var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("DatatablePageLayout", {
    scopedSlots: _vm._u([
      {
        key: "datatable",
        fn: function() {
          return [
            _c("Datatable", {
              attrs: {
                config: _vm.datatableConfig,
                elements: _vm.pages,
                stripe: true,
                draggable: false,
                sst: true,
                pagination: _vm.pagination
              },
              on: {
                filter: _vm.handleFilter,
                action: _vm.handleAction,
                checkbox: _vm.handleCheckbox
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }