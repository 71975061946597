<template>
    <DatatablePageLayout>
        <template v-slot:datatable>
            <Datatable 
                :config="datatableConfig"
                :elements="pages"
                :stripe="true"
                :draggable="false"
                :sst="true"
                :pagination="pagination"
                @filter="handleFilter"
                @action="handleAction"
                @checkbox="handleCheckbox"
            />
        </template>
    </DatatablePageLayout>
</template>

<script>
import PagesService from '@/modules/Resorts/Services/PagesService'
import Datatable from '@/modules/Shared/Components/table/SKDataTable.vue'
import LanguageService from '@/modules/Shared/Services/LanguageService' 
import loader from "@/modules/Shared/Mixins/loader.js";
import PaginationService from '@/modules/Shared/Services/PaginationService'
import DatatablePageLayout from "@/modules/Shared/Pages/DatatablePageLayout"

export default {
    name: 'contents',
    components: {
        DatatablePageLayout,
        Datatable
    },
    mixins: [loader],
    data () {
        return {
            resortUuid: '',
            pages: [],
            datatableConfig: [],
            pagination: {},
            parameters: {filters: [{field: 'currentLanguage', value: 'en'}]}
        }
    },
    async created() {
        this.resortUuid = this.$route.params.uuid
        
        const params = this.parameters
        this.loadAndNotify(async () => {
            await this.prepareDatatableConfig()
            await this.loadDatatablePages(params)
        }, true)
    },
    methods: {
        async prepareDatatableConfig() {
            const langResponse = await LanguageService.getLanguages()
            let languages = langResponse.map((l) => { return {'label': l.acronym, 'value': l.acronym}})
            const publishedOptions = [
                {'label': this.$i18n.t('yes'), 'value':1},
                {'label': this.$i18n.t('no'), 'value':0},
            ]
            
            this.datatableConfig = [
                { 'type': 'text', 'field': 'reference_id', head: this.$t("content.content-list.id-ref"), 'searchable': true, 'sorteable': false },
                { 'type': 'text', 'field': 'reference', head: this.$t("content.content-list.reference"), 'searchable': true, 'sorteable': false },
                { 'type': 'text', 'field': 'updated', head: this.$t("content.content-list.modified"), 'searchable': false, 'sorteable': false },
                // { 'type': 'text', 'field': 'owner', head: this.$t("content.content-list.user"), 'searchable': true, 'sorteable': false },
                { 'type': 'check', 'field': 'published', head: this.$t("content.content-list.publish"), 'searchable': true, 'sorteable': false, 'searchableoptions': publishedOptions, placeholder: this.$i18n.t('content.content-list.all')},
                { 'type': 'text', 'field': 'currentLanguage', head: this.$t("content.content-list.active-languages"), 'searchable': true, 'sorteable': false, 'searchableoptions': languages, 'default': 'en', placeholder: this.$i18n.t('content.content-list.all') },
                { 'type': 'actions', 'field': 'actions', head: this.$t("resorts.webcams.actions"), 'searchable': false, 'sorteable': false }
            ]
        },

        async loadDatatablePages(parameters) {
            let language = this.getLanguageFilter(parameters)
            const onlyLanguage = this.hasOnlyChangedLanguageFilter(parameters)

            let pages = this.pages
            if (!onlyLanguage) {
                pages = await this.requestPages(parameters)
            }

            this.pages = pages.map(
                page => ({
                    ...page,
                    currentLanguage: this.containsLanguage(language, page.translatedLanguages) ? this.$i18n.t('yes') : '-',
                })
            )
            this.parameters = parameters
        },

        async requestPages(parameters) {
            const requestParams = PaginationService.parseParameters(parameters)
            const pagesWithPagination = await PagesService.getPages(this.resortUuid, requestParams)
            this.setDatatablePagination(pagesWithPagination)
            return this.parseResponsePages(pagesWithPagination.data)
        },

        parseResponsePages(pagesFromResponse) {
            return pagesFromResponse.map(
                page => ({
                    ...page,
                    translatedLanguages: page.languages,
                    currentLanguage: '-',
                    owner: page.user.username, 
                    'actions': [ 'edit' ]
                })
            )
        },

        containsLanguage(lang, languagesString) {
            let languages = languagesString.split(',')
            return languages.includes(lang)
        },

        hasOnlyChangedLanguageFilter(parameters) {
            if (
                (this.parameters.page !== 1 && parameters.page === undefined) ||
                (this.parameters.page === undefined && parameters.page !== 1)
            ) {
                return false
            }
            if (this.parameters.per_page !== parameters.per_page) {
                return false
            }
            if (this.parameters.sort_type !== parameters.sort_type) {
                return false
            }
            if (this.parameters.sort_by !== parameters.sort_by) {
                return false
            }

            let intialWithoutLang = this.parameters.filters.filter((f) => f.field !== 'currentLanguage')
            let currentWithoutLang = parameters.filters.filter((f) => f.field !== 'currentLanguage')

            if (intialWithoutLang.length !== currentWithoutLang.length) {
                return false
            }

            return !intialWithoutLang.some(oldFilter => {
                const newFilter = this.filterParamters(currentWithoutLang, oldFilter.field)
                return newFilter.length === 0 || newFilter[0].value !== oldFilter.value
            })
        },

        getLanguageFilter(parameters) {
            const filter = this.filterParamters(parameters.filters, 'currentLanguage')

            if (filter.length <= 0) {
                return '-'
            }

            return filter[0].value
        },

        filterParamters(filters, field) {
            if (filters === undefined) {
                return []
            }

            return filters.filter((f) => { 
                return f.field === field
            })
        },

        setDatatablePagination(pagination) {
            this.pagination = {
                per_page: pagination.per_page,
                total: pagination.total
            }
        },
        
        async handleFilter(params) {
            this.loadAndNotify(async () => {
                await this.loadDatatablePages(params)
            }, true)
        },

        handleAction(type, uuid) {
            const page = this.pages.find(page => page.uuid === uuid);
            switch(type) {
                case 'edit':
                    var currentLanguage = this.getLanguageFilter(this.parameters)
                    this.$router.push({
                        name: 'edit-content',
                        params: {
                            page_uuid: page.uuid,
                            lang: currentLanguage,
                        }
                    })
                    break
            }
        },

        handleCheckbox(uuid, value, field) {
            this.loadAndNotify(async () => {
                await PagesService.savePage(this.resortUuid, uuid, {
                    [field]: value
                })
            })
        }
    }
}
</script>